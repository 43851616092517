<template>
  <div class="ele-body">
    <el-card shadow="never">

      <el-form
        :model="where"
        label-width="77px"
        class="ele-form-search"
        @keyup.enter.native="reload"
        @submit.native.prevent>
        <el-row :gutter="15">
          <el-col :md="8" :sm="12">
            <el-form-item label="时间:">
              <el-date-picker
                style="width: 90%;"
                v-model="where.created_at"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <el-form-item label="等级:">
              <el-select v-model="where.level_id" clearable placeholder="请选择" >
                <el-option
                  v-for="item in levelList"
                  :key="item.value"
                  :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="12">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                @click="reload"
                icon="el-icon-search"
                class="ele-btn-icon">查询
              </el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <ele-pro-table
        ref="table"
        :datasource="url"
        :where="where"
        :columns="columns"
        :response="response"
        :selection.sync="selection"
      >
        <!-- 表头工具栏 -->
        <template slot="toolbar">
          <el-button
            @click="openEdit(null)"
            type="primary"
            icon="el-icon-plus"
            class="ele-btn-icon"
            size="small"
          >添加
          </el-button>
          <el-button
            @click="removeBatch"
            type="danger"
            icon="el-icon-delete"
            class="ele-btn-icon"
            size="small"
          >删除
          </el-button>
<!--          <el-button
            @click="openImport(null)"
            type="primary"
            icon="el-icon-plus"
            class="ele-btn-icon"
            size="small"
          >批量导入
          </el-button>-->
<!--          <el-button
            @click="openEditTime(null)"
            type="primary"
            icon="el-icon"
            class="ele-btn-icon"
            size="small"
          >批量设置时间
          </el-button>-->
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="{ row }">
          <div v-if="row.role_id !== 1">
            <el-link
              @click="openEdit(row)"
              icon="el-icon-edit"
              type="primary"
              :underline="false"
            >修改
            </el-link>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="remove(row)"
              class="ele-action"
            >
              <el-link
                slot="reference"
                icon="el-icon-delete"
                type="danger"
                :underline="false"
              >删除
              </el-link>
            </el-popconfirm>
          </div>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <stem-edit :visible.sync="showEdit" :openEdit="openEdit" :data="current" @done="reload"/>
    <stem-import :visible.sync="showImport" :openImport="openImport"  @done="reload"/>
    <stem-time :visible.sync="showEditTime" :openEditTime="openEditTime"  @done="reload"/>
  </div>
</template>

<script>
import stemEdit from "./stem-edit";
import stemImport from "./stem-import";
import stemTime from "./stem-time";

export default {
  name: "ReadStem",
  components: {
    stemEdit,
    stemImport,
    stemTime,
  },
  data() {
    return {
      // 表格数据接口
      url: "/Admin/read",
      response: {
        statusCode: 200,
        countName:'data.total',
        dataName: 'data.data'
      },
      // 表格列配置
      columns: [
        {
          columnKey: "selection",
          type: "selection",
          width: 45,
          align: "center",
          fixed: "left",
        },
        {
          columnKey: "index",
          type: "index",
          width: 45,
          align: "center",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          prop: "title",
          label: "标题",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        /*{
          prop: "module_name",
          label: "模块",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },*/
        {
          prop: "level_name",
          label: "等级",
          sortable: "custom",
          showOverflowTooltip: true,
          width: 80,
        },
        /*{
          prop: "score",
          label: "分值",
          sortable: "custom",
          showOverflowTooltip: true,
          width: 80,
        },*/
        {
          prop: "answer_time",
          label: "答题时长",
          sortable: "custom",
          showOverflowTooltip: true,
          width: 110,
        },
        {
          prop: "created_at",
          label: "创建时间",
          sortable: "custom",
          showOverflowTooltip: true,
          width: 160,
        },
        {
          columnKey: "action",
          label: "操作",
          width: 230,
          align: "center",
          resizable: false,
          slot: "action",
        },
      ],
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 表格搜索条件
      where: {  },
      // 等级下拉数据
      levelList: [],
      // 是否显示导入弹窗
      showImport: false,
      showEditTime: false,
    };
  },
  mounted() {
    this.queryLevelList()
  },
  methods: {
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({page: 1});
    },
    /* 显示编辑 */
    openEdit(row) {
      //console.log(row)
      this.current = row;
      this.showEdit = !this.showEdit;
    },
    /* 显示批量导入 */
    openImport(row) {
      this.showImport = !this.showImport;
    },
    /* 显示批量设置 */
    openEditTime(row) {
      this.showEditTime = !this.showEditTime;
    },
    /* 删除 */
    remove(row) {
      const loading = this.$loading({lock: true});
      this.$http
        .delete("/Admin/reaInfo/" + row.id)
        .then((res) => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({type: "success", message: res.data.msg});
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error("请至少选择一条数据");
        return;
      }
      this.$confirm("确定要删除选中的等级吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({lock: true});
          this.$http.delete(
              "/Admin/read/" +
              this.selection.map((d) => d.id).toString()
            ).then((res) => {
              loading.close();
              if (res.data.code === 200) {
                this.$message({type: "success", message: res.data.msg});
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch((e) => {
              loading.close();
              this.$message.error(e.message);
            });
        }).catch(() => {
        });
    },
    // 重置
    reset() {
      this.where = {};
      this.$nextTick(() => {
        this.reload();
      });
    },
    /* 查询等级列表 */
    queryLevelList() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.levelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
  },
};
</script>

<style scoped>
</style>
